<template>
  <div class="main">
    <SettingsNavBar style="grid-area: navbar" />

    <div style="display: flex; flex-direction: column; grid-area: content; overflow: auto">
      <div
        style="
          display: flex;
          flex-direction: column;
          width: 640px;
          padding-top: 16px;
          padding-bottom: 24px;
          margin-left: 64px;
          margin-right: auto;
        "
      >
        <h1 style="font-size: 24px; font-weight: 500; color: var(--gray-1); line-height: 40px; margin-bottom: 12px">
          {{ route.meta.title }}
        </h1>
        <h2 style="font-size: 16px; color: var(--gray-2); line-height: 20px; margin-bottom: 8px">
          {{ route.meta.subtitle }}
        </h2>
        <div style="height: 1px; background: var(--gray-5); margin-block: 24px"></div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const route = useRoute();

useHead({ titleTemplate: (title) => `Paramètres - ${title}` });
</script>

<style scoped lang="scss">
.main {
  display: grid;
  grid-template-areas: "navbar content";
  grid-template-columns: auto 1fr;
  height: 100dvh;
  width: 100dvw;
  background: var(--gray-8);
  overflow: hidden;
}
</style>
